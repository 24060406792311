
import {Component, Vue, Prop} from 'vue-property-decorator';
import CandidateForm from "@/components/ats/Candidate/CandidateForm.vue"
import TitleManager from "@/mixins/TitleManager.vue";
import {mixins} from "vue-class-component";
import {ICandidate, IInterviewDB, StoreAction, IAddress, LabelValue,} from "@/types" ;
import {Action} from 'vuex-class';
import {ROUTES} from '@/data';
import clonedeep from "lodash.clonedeep";
import {NaNOrValue, NaNOrValueMore} from "@/helpers/commons";
import moment from "moment-timezone";
import ActionButton from '@/components/ActionButton.vue';

@Component({
    name: "NewCandidate",
    components: {
        CandidateForm, ActionButton
    }
})
export default class NewCandidate extends mixins(TitleManager) {

    pageTitle: any = this.$t('page.candidates.add.title');
    errors: any = {};
    loading = false;
    candidate: ICandidate | any = {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        picture: "",
        cv: {
            path: null
        },
        birthPlace: "",
        birthCountry: "",
        jobs: [],
        origin: "",
        address: {
            name: '',
            street: '',
            complement: '',
            city: '',
            citySlug: '',
            countryCode: '',
        },
        dataAnalysis: {},
        regularAvailabilities: (new Array(7)).fill({
            morning: true,
            afternoon: true,
            evening: true,
            night: true
        })
    };

    NaNOrValue = NaNOrValue;
    moment = moment;

    @Action('actions/createCandidate') createCandidate!: StoreAction;
    @Action('actions/getGlobalConfiguration') getGlobalConfiguration!: StoreAction;

    async create() {
        this.loading = true;
        this.errors = {};

        try {
            const candidate = clonedeep(this.candidate);

            if (this.candidate.jobs?.length) {
                candidate.jobs.forEach((job:any) => {
                    if (job.certifications) {
                        job.certifications = job.certifications.map((c:any) => { return this.NaNOrValue(c) });
                    }
                });
            }
            candidate.birthDate = candidate.birthDate ? moment(candidate.birthDate, this.$t('date.format')).valueOf() : null;
            const res = await this.createCandidate(candidate);
            await this.getGlobalConfiguration();
            await this.$router.push({name: ROUTES.ATS.CANDIDATE._ROOT, params: {candidateId: res._id}});
        } catch (e) {
            this.errors = e.errors;
            console.log(e);
        }
        this.loading = false;
    }


    beforeMount() {
    }
}
